import React from 'react'

import cytoscape from 'cytoscape'
import cola from 'cytoscape-cola'
import css from './Cytoscape.module.css'
import panzoom from 'cytoscape-panzoom'
import PropTypes from 'prop-types'
import { createRef } from 'react/cjs/react.production.min'
import Callbacks from './Callbacks'

cytoscape.use(cola)
panzoom(cytoscape)

class Cytoscape extends React.Component {
  constructor(props) {
    super(props)
    this.root = createRef()
    this.callbacks = null
  }

  componentDidMount() {
    console.assert(!!this.root.current)

    const layoutParams = this.makeLayoutParams(this.props.layoutTimeout)
    const cy = drawNetwork(
      this.props.elements,
      this.props.style,
      this.root.current,
      layoutParams,
      this.props.selectElements
    )

    this.callbacks = new Callbacks(cy, layoutParams)
    this.props.setCallbacks(this.callbacks)
  }

  makeLayoutParams(timeout) {
    return {
      name: 'cola',
      maxSimulationTime: timeout * 1000,
      ungrabifyWhileSimulating: false,
      animate: true,
      padding: 5,
      fit: true,
      avoidOverlap: true,
      randomize: true
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.layoutTimeout !== prevProps.layoutTimeout) {
      this.callbacks.resetLayout()
    }
    if (
      this.props.elements.nodes !== prevProps.elements.nodes ||
      this.props.elements.edges !== prevProps.elements.edges ||
      this.props.style !== prevProps.style
    ) {
      const layoutParams = this.makeLayoutParams(this.props.layoutTimeout)
      const cy = drawNetwork(
        this.props.elements,
        this.props.style,
        this.root.current,
        layoutParams,
        this.props.selectElements
      )
      this.callbacks = new Callbacks(cy, layoutParams)
      this.props.setCallbacks(this.callbacks)
    }
  }

  render() {
    return (
      <div className={css.cytoscape}>
        <div ref={this.root} className={css.inner} />
      </div>
    )
  }
}

export default Cytoscape

function drawNetwork(elements, style, container, layout, selectElements) {
  return setup(
    {
      elements,
      container,
      layout,
      style
    },
    selectElements
  )
}

export function setup(cytoSetup, selectElements) {
  const cy = cytoscape(cytoSetup)

  // TODO: decomment here for panzoom
  const defaults = {
    zoomFactor: 0.05, // zoom factor per zoom tick
    zoomDelay: 45, // how many ms between zoom ticks
    minZoom: 0.1, // min zoom level
    maxZoom: 10, // max zoom level
    fitPadding: 50, // padding when fitting
    panSpeed: 10, // how many ms in between pan ticks
    panDistance: 10, // max pan distance per tick
    panDragAreaSize: 75, // the length of the pan drag box in which the vector for panning is calculated (bigger = finer control of pan speed and direction)
    panMinPercentSpeed: 0.25, // the slowest speed we can pan by (as a percent of panSpeed)
    panInactiveArea: 8, // radius of inactive area in pan drag box
    panIndicatorMinOpacity: 0.5, // min opacity of pan indicator (the draggable nib); scales from this to 1.0
    zoomOnly: false, // a minimal version of the ui only with zooming (useful on systems with bad mousewheel resolution)
    fitSelector: undefined, // selector of elements to fit
    animateOnFit: function () {
      // whether to animate on fit
      return false
    },
    fitAnimationDuration: 1000, // duration of animation on fit

    // icon class names
    sliderHandleIcon: 'fa fa-minus',
    zoomInIcon: 'fa fa-plus',
    zoomOutIcon: 'fa fa-minus',
    resetIcon: 'fa fa-expand'
  }

  // add the panzoom control
  cy.panzoom(defaults)

  cy.on('tap', 'edge', function (event) {
    selectElements(event.target.data().meta)
  })

  cy.on('tap', 'node', function (event) {
    selectElements(event.target.data().meta)
  })

  return cy
}

Cytoscape.propTypes = {
  elements: PropTypes.object.isRequired,
  style: PropTypes.arrayOf(PropTypes.object),
  layoutTimeout: PropTypes.number.isRequired,
  selectElements: PropTypes.func,
  setCallbacks: PropTypes.func
}
