import { saveAs } from 'file-saver'

class Callbacks {
  constructor(cy, params) {
    this.cy = cy
    this.params = params

    this.resetLayout = this.resetLayout.bind(this)
    this.resetZoom = this.resetZoom.bind(this)
    this.resize = this.resize.bind(this)
    this.enhancesLayout = this.enhancesLayout.bind(this)
    this.downloadJSON = this.downloadJSON.bind(this)
    this.savePNG = this.savePNG.bind(this)
  }

  resetZoom() {
    this.cy.fit()
  }

  resize() {
    this.cy.resize()
  }

  enhancesLayout() {
    this.params.randomize = false
    this.cy.layout(this.params).run()
  }

  resetLayout() {
    this.params.randomize = true
    this.cy.layout(this.params).run()
  }

  downloadJSON() {
    saveJson(this.cy.json())
  }

  savePNG() {
    saveAs(this.cy.png({ full: true }), 'network.png')
  }
}

function saveJson(json) {
  const jsonBlob = new Blob([JSON.stringify(json)], {
    type: 'application/javascript;charset=utf-8'
  })
  saveAs(jsonBlob, 'network.json')
}

export default Callbacks
