import React from 'react'
import css from './Setup.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const Setup = ({ callbacks, children }) => {
  return callbacks ? (
    <div className={css.container}>
      <div className={css.buttons}>
        <Icon
          iconLabel='level-up-alt'
          onClick={callbacks.enhancesLayout}
          onMouseOver='Enhances Layout'
        />
        <Icon
          iconLabel='undo-alt'
          onClick={callbacks.resetLayout}
          onMouseOver='Reset Layout'
        />
        <Icon
          iconLabel='expand'
          onClick={callbacks.resetZoom}
          onMouseOver='Reset Zoom'
        />
        <Icon
          iconLabel='image'
          onClick={callbacks.savePNG}
          onMouseOver='Save PNG'
        />
        <Icon
          iconLabel='file-download'
          onClick={callbacks.downloadJSON}
          onMouseOver='Download JSON'
        />
      </div>
      <div>{children}</div>
    </div>
  ) : (
    <div className={css.loading}>Loading...</div>
  )
}

const Icon = ({ iconLabel, onClick, onMouseOver }) => (
  <FontAwesomeIcon
    title={onMouseOver}
    icon={iconLabel}
    className={css.icon}
    color='rgb(70, 71, 73)'
    onClick={onClick}
  />
)

export default Setup

Setup.propTypes = {
  callbacks: PropTypes.object
}
